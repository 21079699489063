import React from "react";
import "./Banner.css";
import { Card, Button, Col, Row } from "react-bootstrap";
import img1 from "../../Image/engineer-workers-icon 1.png";
import img2 from "../../Image/Group 439.png";
import img3 from "../../Image/Group 440.png";
import { Link } from "react-router-dom";

function Banner({ userData }) {
  return (
    <div className="banner-container" id="web-banner-container" dir="rtl">
      <Row className="banner-content">
        <Col lg={8} md={12} className="left-content">
          <div className="text-content">
            <h1 className="welcome-text">
              مرحباً بك، مهندس <sapn>{userData && userData.displayName}</sapn>،
              في فرع <sapn>{userData && userData.branchName}</sapn>
            </h1>
            <p className="description">
              رسم تقدم استشارات هندسية متكاملة وحلول تصميم مبتكرة. فريقنا من
              الخبراء يوفر استشارات مهنية في الاعمال الكهربئيه و التصميم
              المعماري، المدني، والميكانيكي لضمان نجاح مشاريعكم بكفاءة
              واحترافية.
            </p>
          </div>
          <div className="image-content">
            <div className="image-cover"></div>
          </div>
        </Col>
        <Col lg={4} md={12} className="right-content">
          <h2 className="section-title">الأقسام</h2>
          <div className="cards-container">
            {/* <Card className="custom-card">
              <Card.Img variant="left" src={img1} />
              <Link to="/Sub-page/Subscribers">
                <Card.Body>
                  <Card.Title>المشتركين</Card.Title>
                  <Card.Text className="card-description">
                    قائمة الأعضاء أو الشركات التي تستخدم خدمات الموقع
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card> */}

            <Card className="custom-card">
              <Card.Img variant="left" src={img3} />
              <Link to="/Sub-page/Projects">
                <Card.Body>
                  <Card.Title> المشاريع</Card.Title>
                  <Card.Text className="card-description">
                    نظرة عامة على المشاريع الهندسية، التقدم، والنطاق
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>
            <Card className="custom-card">
              <Card.Img variant="left" src={img2} />
              <Link to="/operate-maintain">
                <Card.Body>
                  <Card.Title>العمليات والصيانه</Card.Title>
                  <Card.Text className="card-description">
                    ضمان سير العمل بسلاسة وصيانة المعدات في المشاريع
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>

            <Card className="custom-card">
              <Card.Img variant="left" src={img3} />
              <Link to="/Sub-page/special-projects">
                <Card.Body>
                  <Card.Title> المشاريع الخاصه</Card.Title>
                  <Card.Text className="card-description">
                    نظرة عامة على المشاريع الهندسية، التقدم، والنطاق
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Banner;
