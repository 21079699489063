import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import img from "../../Image/image 2.png";
import img2 from "../../Image/image 2 (1).png";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer mt-5" dir="rtl">
      <div className="container-fluid">
        <div className="row ">
          <div
            className="col-md-3 text-center py-5 "
            style={{ backgroundColor: "rgba(76, 175, 79, 1)", color: "white" }}
          >
            <div className="mb-3">
              <img src={img} alt="Footer Image" className="img-fluid mb-2" />
            </div>
            <p className="Contactus">اتصل بنا اليوم</p>
            <p className="phoneNumber">112759717</p>
            <div className="d-flex justify-content-center">
              <a href="#" className="text-decoration-none mx-2">
                <div className="rounded-circle bg-white p-2">
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    style={{ color: "rgba(76, 175, 79, 1)" }}
                  />
                </div>
              </a>
              <a href="#" className="text-decoration-none mx-2">
                <div className="rounded-circle bg-white p-2">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    style={{ color: "rgba(76, 175, 79, 1)" }}
                  />
                </div>
              </a>
              <a href="#" className="text-decoration-none mx-2">
                <div className="rounded-circle bg-white p-2">
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    style={{ color: "rgba(76, 175, 79, 1)" }}
                  />
                </div>
              </a>
              <a href="#" className="text-decoration-none mx-2">
                <div className="rounded-circle bg-white p-2">
                  <FontAwesomeIcon
                    icon={faYoutube}
                    style={{ color: "rgba(76, 175, 79, 1)" }}
                  />
                </div>
              </a>
            </div>
          </div>

          <div
            className="col-md-9 py-4 pr-5"
            style={{
              backgroundColor: "rgba(103, 103, 105, 1)",
              color: "white",
            }}
          >
            <div className="row">
              <div className="col-md-4 py-4">
                <h5>الشركة</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link
                      to="/about"
                      className="text-white text-decoration-none"
                    >
                      عن الشركة
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/projects"
                      className="text-white text-decoration-none"
                    >
                      مشاريعنا
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contactus"
                      className="text-white text-decoration-none"
                    >
                      اتصالات
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-4 py-4">
                <h5>خدماتنا</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link
                      to="/Sub-page/Operations-Maintenance"
                      className="text-white text-decoration-none"
                    >
                      العمليات والصيانة
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Sub-page/Projects"
                      className="text-white text-decoration-none"
                    >
                      المشاريع
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-4 flex-col-reverse items-center  flex  py-4 text-center">
                <p>
                  Created by :{" "}
                  <span
                    style={{ color: "rgba(76, 175, 79, 1)", cursor: "pointer" }}
                    onClick={() =>
                      (window.location.href = "https://wa.me/201026270790")
                    }
                  >
                    NG-Technology
                  </span>
                </p>
                <img src={img2} alt="Footer Image" className="img-fluid mb-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
