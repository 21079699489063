import React, { useState, useEffect, useMemo } from "react";
import * as XLSX from "xlsx";
import noDataImage from "../../Image/App Illustrations.jpg";
import img from "../../Image/microsoft-excel-icon 1.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./SearchRequests.css";
import axios from "axios";
import { Url } from "../../function/FunctionApi";
import { getCookie } from "../../Pages/Login/Login";
import { Link } from "react-router-dom";

const RequestCard = ({ request, isChecked, onChange }) => {
  const getProjectTypeRoute = (orderType) => {
    switch (orderType) {
      case "العمليات":
        return "operation";
      case "المشاريع":
        return "newproject";
      case "الصيانة":
        return "maintains";
      default:
        return "privateproject";
    }
  };
  const projectTypeRoute = getProjectTypeRoute(request.type);

  return (
    <div className="request-card">
      <div className="checkbox-container">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onChange}
          className="custom-checkbox"
        />
      </div>
      {request.type === "المشاريع الخاصة" ? (
        <Link to={`/project/${projectTypeRoute}/${request.id}`}>
          <p>الفرع: {request.branchName}</p>
          <p> اسم المشروع : {request.projectPlace}</p>
          <p> اسم العميل: {request.customer}</p>
          <p>نوع المشروع: {request.type}</p>
          <p>حالة الطلب: {request.isArchive ? "مؤرشف" : "غير مؤرشف"}</p>
        </Link>
      ) : (
        <Link to={`/project/${projectTypeRoute}/${request.id}`}>
          <p>الفرع: {request.branchName}</p>
          <p>رقم الطلب: {request.faultNumber || request.orderNumber}</p>
          <p>مقدم الطلب: {request.contractor}</p>
          <p>نوع المشروع: {request.type}</p>
          <p>حالة الطلب: {request.isArchive ? "مؤرشف" : "غير مؤرشف"}</p>
        </Link>
      )}
    </div>
  );
};

const Filter = ({ name, value, onChange, options }) => (
  <div className="filter">
    {name === "requestNumber" ? (
      <input
        type="number"
        name={name}
        placeholder="رقم الطلب"
        value={value}
        onChange={onChange}
      />
    ) : name === "contractor" ? (
      <input
        type="text"
        name={name}
        placeholder="مقدم الطلب"
        value={value}
        onChange={onChange}
      />
    ) : name === "projectType" ? (
      <input
        type="text"
        name={name}
        placeholder="نوع المشروع"
        value={value}
        onChange={onChange}
      />
    ) : name === "startDate" ? (
      <div className="filterDate">
        <label>
          <span>تاريخ البدء:</span>
          <input
            type="date"
            name="startDate"
            onChange={onChange}
            placeholder="اختر تاريخ البدء"
          />
        </label>
      </div>
    ) : name === "endDate" ? (
      <div className="filterDate">
        <label>
          <span>تاريخ الانتهاء:</span>
          <input
            type="date"
            name="endDate"
            onChange={onChange}
            placeholder="اختر تاريخ الانتهاء"
          />
        </label>
      </div>
    ) : (
      <select name={name} value={value} onChange={onChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    )}
  </div>
);

// Modal component
const Modal = ({ isOpen, onClose, onExport }) =>
  isOpen && (
    <div className="modal-open">
      <div className="modal-content">
        <h2>تصدير البيانات</h2>
        <p>هل أنت متأكد أنك تريد تصدير الطلبات المحددة إلى Excel؟</p>
        <button className="export-button" onClick={onExport}>
          تصدير
        </button>
        <button className="close-modal-button" onClick={onClose}>
          إغلاق
        </button>
      </div>
    </div>
  );

const SearchRequests = () => {
  const [filters, setFilters] = useState({
    branch: "",
    requestNumber: "",
    contractor: "",
    projectType: "",
    requestStatus: "",
    searchQuery: "",
    startDate: "",
    endDate: "",
  });
  const [apiData, setApiData] = useState([]);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userCookie = getCookie("user");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = JSON.parse(userCookie).token;
        const response = await axios.get(`${Url}Admin/AllOrders`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setApiData([
          ...(response.data.maintencesOrders || []),
          ...(response.data.privateProjects || []),
          ...(response.data.newProjects || []),
          ...(response.data.operationOrders || []),
        ]);
      } catch (err) {
        setError("فشل في تحميل البيانات، يرجى المحاولة لاحقاً.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userCookie]);

  const filteredRequests = useMemo(() => {
    return apiData.filter((request) => {
      const {
        branch,
        requestNumber,
        contractor,
        projectType,
        requestStatus,
        startDate,
        endDate,
        searchQuery,
      } = filters;

      const matchesBranch =
        !branch || request.branchName?.toLowerCase() === branch.toLowerCase();
      const matchesRequestNumber =
        !requestNumber ||
        request.orderNumber?.toString().includes(requestNumber);
      const matchesContractor =
        !contractor ||
        request.contractor?.toLowerCase().includes(contractor.toLowerCase());
      const matchesProjectType =
        !projectType ||
        (typeof request.type === "string" &&
          request.type.toLowerCase().includes(projectType.toLowerCase()));
      const matchesRequestStatus =
        !requestStatus || request.isArchived?.toString() === requestStatus;

      const matchesDate =
        (!startDate || new Date(request.orderDate) >= new Date(startDate)) &&
        (!endDate || new Date(request.orderDate) <= new Date(endDate));

      const matchesSearchQuery =
        !searchQuery ||
        Object.values(request).some((value) =>
          value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );

      return (
        matchesBranch &&
        matchesRequestNumber &&
        matchesContractor &&
        matchesProjectType &&
        matchesRequestStatus &&
        matchesDate &&
        matchesSearchQuery
      );
    });
  }, [apiData, filters]);
  console.log(apiData);
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleCheckboxChange = (index) => {
    setSelectedRequests((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const handleSelectAll = () => {
    setSelectedRequests(
      selectedRequests.length === filteredRequests.length
        ? []
        : filteredRequests.map((_, index) => index)
    );
  };

  const exportToExcel = () => {
    const selectedData = filteredRequests
      .filter((_, index) => selectedRequests.includes(index))
      .map((request) => ({
        "نوع المشروع": request.type,
        "اسم الفرع": request.branchName,
        الاستشاري: request.consultant,
        المقاول: request.contractor,
        الحي: request.district,
        "رقم المحطه": request.stationNumber,
        "تاريخ الطلب": new Date(request.orderDate).toLocaleDateString("ar-EG"),
        "رقم الطلب": request.orderNumber || request.faultNumber,
        المهندس: request.userName,
        "القيمه التقديريه": request.estimatedValue,
        "القيمه الفعليه": request.actualValue,
        "رقم المستخلص": request.estimatedValue,

        أرشيف: request.isArchive ? "نعم" : "لا",
        "تنفيذ الشبكة": request.isNetworkImplemented ? "نعم" : "لا",
        "عدد صور الموقع": request.sitePhotos.length,
        "عدد صور السلامة": request.safetyWastePhotos.length,
        "عدد صور النماذج": request.modelPhotos.length,
        ملاحظات: request.note || "لا توجد ملاحظات",
      }));

    const worksheet = XLSX.utils.json_to_sheet(selectedData);

    const headerStyle = {
      font: { bold: true, color: { rgb: "#222" } },
      fill: { fgColor: { rgb: "4F81BD" } },
      alignment: { vertical: "center", horizontal: "center" },
    };
    const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let C = headerRange.s.c; C <= headerRange.e.c; C++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      if (!worksheet[cellAddress]) continue;
      worksheet[cellAddress].s = headerStyle;
    }

    const yesStyle = {
      fill: { fgColor: { rgb: "C6EFCE" } },
      font: { color: { rgb: "006100" } },
      alignment: { horizontal: "center" },
    };
    const noStyle = {
      fill: { fgColor: { rgb: "FFC7CE" } },
      font: { color: { rgb: "9C0006" } },
      alignment: { horizontal: "center" },
    };

    for (let R = 1; R <= headerRange.e.r; R++) {
      const isArchiveCell = worksheet[XLSX.utils.encode_cell({ r: R, c: 11 })]; // "أرشيف" column
      const networkImplementedCell =
        worksheet[XLSX.utils.encode_cell({ r: R, c: 12 })]; // "تنفيذ الشبكة" column
      const rowStyle = {
        fill: { fgColor: { rgb: R % 2 === 0 ? "F2F2F2" : "FFFFFF" } },
        alignment: { horizontal: "center" },
      };

      for (let C = 0; C <= headerRange.e.c; C++) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (worksheet[cellAddress]) worksheet[cellAddress].s = rowStyle;
      }

      if (isArchiveCell && isArchiveCell.v === "نعم") {
        isArchiveCell.s = yesStyle;
      } else if (isArchiveCell) {
        isArchiveCell.s = noStyle;
      }

      if (networkImplementedCell && networkImplementedCell.v === "نعم") {
        networkImplementedCell.s = yesStyle;
      } else if (networkImplementedCell) {
        networkImplementedCell.s = noStyle;
      }
    }

    worksheet["!cols"] = [
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
      { wch: 18 },
      { wch: 20 },
      { wch: 15 },
      { wch: 30 },
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "طلبات");

    XLSX.writeFile(workbook, "طلبات_محددة.xlsx");
    setIsModalOpen(false);
  };

  if (loading) return <Skeleton count={10} height={50} />;
  if (error) return <div>{error}</div>;

  const renderFilterOptions = (key) => {
    const optionsMap = {
      branch: [
        { value: "", label: "الفرع" },
        { value: "الرياض", label: "الرياض" },
        { value: "حائل", label: "حائل" },
      ],
      projectType: [
        { value: "", label: "نوع المشروع" },
        { value: "ordersSubs", label: "مشتركين" },
        { value: "operationOrders", label: "عمليات وصيانة" },
        { value: "newProjects", label: "مشاريع" },
      ],
      requestStatus: [
        { value: "", label: "حالة الطلب" },
        { value: "true", label: "مؤرشف" },
        { value: "false", label: "غير مؤرشف" },
      ],
    };
    return optionsMap[key] || [];
  };

  return (
    <div className="search-requests-container">
      <div className="filters-container">
        <div className="select-all-checkbox">
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={
                selectedRequests.length === filteredRequests.length &&
                filteredRequests.length > 0
              }
              onChange={handleSelectAll}
              className="custom-checkbox"
            />
          </div>
        </div>
        {Object.keys(filters).map(
          (key) =>
            key !== "searchQuery" && (
              <>
                <Filter
                  key={key}
                  name={key}
                  value={filters[key]}
                  onChange={handleFilterChange}
                  options={renderFilterOptions(key)}
                />
              </>
            )
        )}
        <h1>{filteredRequests.length}</h1>
      </div>
      <div className="cards-container">
        {filteredRequests.length > 0 ? (
          filteredRequests.map((request, index) => (
            <RequestCard
              key={index}
              request={request}
              isChecked={selectedRequests.includes(index)}
              onChange={() => handleCheckboxChange(index)}
            />
          ))
        ) : (
          <div className="changes-cards-container">
            <img style={{ width: "250px" }} src={noDataImage} alt="No Data" />
            <div>لا توجد الطلبات.</div>
          </div>
        )}
      </div>
      {selectedRequests.length > 0 && (
        <div className="div-open-modal">
          <button
            className="open-modal-button"
            onClick={() => setIsModalOpen(true)}
          >
            تصدير <img src={img} alt="Export to Excel" />
          </button>
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onExport={exportToExcel}
      />
    </div>
  );
};

export default SearchRequests;
