import React, { useState } from "react";
import * as XLSX from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import { Url } from "../../function/FunctionApi";
import axios from "axios";
import Swal from "sweetalert2";
import { officeNameMap } from "../../util/officeConstants";

const Form = ({ ApiData, id, type }) => {
  const navigate = useNavigate();
  console.log(type);
  const {
    projectType,
    orderNumber,
    contractor,
    district,
    station,
    modelPhotos,
    sitePhotos,
    safetyWastePhotos,
    note,
    isExist,
  } = ApiData;

  let orderId =
    ApiData.faultNumber || ApiData.orderNumber || ApiData.projectName;

  const [showModal, setShowModal] = useState(false);

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>${ApiData.type}</title>
          <style>
            body { font-family: 'Amiri', sans-serif; direction: rtl; text-align: right; }
            .container { margin: 20px; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid #000; padding: 10px; text-align: right; }
            th { background-color: #f2f2f2; }
            .image-container { display: flex; justify-content: space-between; margin-bottom: 20px; }
            .file-item { margin: 5px; }
          </style>
        </head>
        <body>
          <div class="container">
            <h2>
              ${
                ApiData.type === "المشاريع الخاصة"
                  ? `اسم المشروع: ${ApiData.projectName}`
                  : ApiData.type === "الصيانة"
                  ? `رقم المشروع: ${ApiData.faultNumber}`
                  : `رقم المشروع: ${ApiData.orderNumber}`
              }
            </h2>
            <table>
              <tr>
                <th>${
                  ApiData.type === "المشاريع الخاصة"
                    ? "مكان المشروع"
                    : "المقاول"
                }</th>
                <td>${
                  ApiData.type === "المشاريع الخاصة"
                    ? ApiData.projectName
                    : ApiData.contractor
                }</td>
              </tr>
              <tr><th>الحي</th><td>${ApiData.district}</td></tr>
              <tr><th>رقم المحطه</th><td>${
                ApiData.stationNumber || "غير متوفر"
              }</td></tr>
              <tr><th>تاريخ الطلب</th><td>${
                ApiData.orderDate.split("T")[0]
              }</td></tr>
              <tr><th>ملاحظات</th><td>${
                ApiData.note || "لا يوجد ملاحظات"
              }</td></tr>
            </table>
            <h4>النموزج و صور الموقع:</h4>
            <div class="image-container">
              <div class="file-list">
                <h5>النموزج:</h5>
                ${modelPhotos
                  .map((photo) => {
                    const isPdf = photo.url.endsWith(".pdf");
                    return isPdf
                      ? `<a href="https://www.rasmconsult.com${photo.url}" target="_blank" style="display: block; margin: 5px;">اضغط لرؤية الملف</a>`
                      : `<img src="https://www.rasmconsult.com${photo.url}" alt="Model Image" style="width: 250px; margin: 5px;" />`;
                  })
                  .join("")}
              </div>
              <div class="file-list">
                <h5>صور الموقع:</h5>
                ${sitePhotos
                  .map((photo) => {
                    const isPdf = photo.url.endsWith(".pdf");
                    return isPdf
                      ? `<a href="https://www.rasmconsult.com${photo.url}" target="_blank" style="display: block; margin: 5px;">اضغط لرؤية الملف</a>`
                      : `<img src="https://www.rasmconsult.com${photo.url}" alt="Site Image" style="width: 250px; margin: 5px;" />`;
                  })
                  .join("")}
              </div>
            </div>
            <h4>مخالفات السلامة:</h4>
            ${
              safetyWastePhotos.length > 0
                ? `<div class="file-list">
                    ${safetyWastePhotos
                      .map((photo) => {
                        const isPdf = photo.url.endsWith(".pdf");
                        return isPdf
                          ? `<a href="https://www.rasmconsult.com${photo.url}" target="_blank" style="display: block; margin: 5px;">اضغط لرؤية الملف</a>`
                          : `<img src="https://www.rasmconsult.com${photo.url}" alt="Safety Image" style="width: 250px; margin: 5px;" />`;
                      })
                      .join("")}
                  </div>`
                : `<p>لا توجد مخالفات سلامة.</p>`
            }
            <h4>ملاحظات إضافية:</h4>
            <p>${note || "لا توجد ملاحظات إضافية"}</p>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleExportExcel = () => {
    const ws = XLSX.utils.json_to_sheet([
      {
        "نوع المشروع": ApiData.type,
        "اسم الفرع": ApiData.branchName,
        "الاستشاري": ApiData.consultant,
        "المقاول": ApiData.contractor,
        "الحي": ApiData.district,
        "رقم المحطه": ApiData.stationNumber,
        "تاريخ الطلب": new Date(ApiData.orderDate).toLocaleDateString("ar-EG"),
        "رقم الطلب": ApiData.orderNumber || ApiData.faultNumber,
        "المهندس": ApiData.userName,
        "أرشيف": ApiData.isArchive ? "نعم" : "لا",
        "تنفيذ الشبكة": ApiData.isNetworkImplemented ? "نعم" : "لا",
        "عدد صور الموقع": ApiData.sitePhotos.length,
        "عدد صور السلامة": ApiData.safetyWastePhotos.length,
        "عدد صور النماذج": ApiData.modelPhotos.length,
        "ملاحظات": ApiData.note || "لا توجد ملاحظات",
      },
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "الطلب");
    XLSX.writeFile(wb, "order.xlsx");
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `${Url}Search/delete-by-orderidWithType?orderId=${orderId}&type=${type}`,
        {
          params: {
            orderId: id,
            type: type,
          },
        }
      );

      if (response.status === 200) {
        setShowModal(false);
        showCustomAlert("تم حذف الطلب بنجاح");
        navigate("/search-requests");
      } else {
        showCustomAlert("حدث خطأ أثناء حذف الطلب");
      }
    } catch (error) {
      showCustomAlert("حدث خطأ أثناء حذف الطلب");
      console.error(error);
    }
  };

  const showCustomAlert = (message) => {
    Swal.fire({
      text: message,
      icon: "success",
      confirmButtonText: "موافق",
    });
  };

  const ShowDataMaintains = (data) => {
    return (
      <div className="w-full">
        <div>
          <div className="input-group">
            <div className="groupInput">
              <label>الفرع</label>
              <input type="text" value={data.branchName} readOnly />
            </div>
            <div className="groupInput">
              <label>المستشار</label>
              <input type="text" value={data.consultant} readOnly />
            </div>

            <div className="groupInput">
              <label>المقاول</label>
              <input type="text" value={data.contractor} readOnly />
            </div>
          </div>
          <div className="input-group">
            <div className="groupInput">
              <label>الحي</label>
              <input type="text" value={data.district} readOnly />
            </div>
            <div className="groupInput">
              <label>مده التنفيذ</label>
              <input
                type="text"
                value={data.durationOfImplementation}
                readOnly
              />
            </div>

            <div className="groupInput">
              <label>المكتب</label>
              <input type="text" value={officeNameMap[data.office]} readOnly />
            </div>
          </div>
          <div className="input-group">
            <div className="groupInput">
              <label>القيمه الفعليه</label>
              <input type="text" value={data.actualValue} readOnly />
            </div>
            <div className="groupInput">
              <label>القيمه المقدره</label>
              <input type="text" value={data.estimatedValue} readOnly />
            </div>

            <div className="groupInput">
              <label>رقم المستخلص</label>
              <input type="text" value={data.extractNumber} readOnly />
            </div>
          </div>
          <div className="input-group">
            <div className="groupInput">
              <label>موقف التنفيذ</label>
              <input
                type="text"
                value={
                  data.situation === "finish"
                    ? "تم التنفيذ"
                    : data.situation === "pending"
                    ? "جاري"
                    : "لم ينتهي"
                }
                readOnly
              />
            </div>
            <div className="groupInput">
              <label>وصف المشروع</label>
              <input type="text" value={data.workDescription} readOnly />
            </div>

            <div className="groupInput">
              <label> رقم المحطه</label>
              <input type="text" value={data.stationNumber} readOnly />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ShowDataOperations = (data) => {
    return (
      <div className="w-full">
        <div>
          <div className="input-group">
            <div className="groupInput">
              <label>الفرع</label>
              <input type="text" value={data.branchName} readOnly />
            </div>
            <div className="groupInput">
              <label>المستشار</label>
              <input type="text" value={data.consultant} readOnly />
            </div>

            <div className="groupInput">
              <label>المقاول</label>
              <input type="text" value={data.contractor} readOnly />
            </div>
          </div>
          <div className="input-group">
            <div className="groupInput">
              <label>الحي</label>
              <input type="text" value={data.district} readOnly />
            </div>
            <div className="groupInput">
              <label>مده التنفيذ</label>
              <input
                type="text"
                value={data.durationOfImplementation}
                readOnly
              />
            </div>

            <div className="groupInput">
              <label>المكتب</label>
              <input type="text" value={officeNameMap[data.office]} readOnly />
            </div>
          </div>
          <div className="input-group">
            <div className="groupInput">
              <label>القيمه الفعليه</label>
              <input type="text" value={data.actualValue} readOnly />
            </div>
            <div className="groupInput">
              <label>القيمه المقدره</label>
              <input type="text" value={data.estimatedValue} readOnly />
            </div>

            <div className="groupInput">
              <label>رقم المستخلص</label>
              <input type="text" value={data.extractNumber} readOnly />
            </div>
          </div>
          <div className="input-group">
            <div className="groupInput">
              <label>تاريخ العطل</label>
              <input
                type="text"
                value={data.faultDate.split("T")[0]}
                readOnly
              />
            </div>
            <div className="groupInput">
              <label>نوع العطل</label>
              <input type="text" value={data.faultType} readOnly />
            </div>

            <div className="groupInput">
              <label>تاريخ استلام امر العمل</label>
              <input
                type="text"
                value={data.receiveDateTime.split("T")[0]}
                readOnly
              />
            </div>
          </div>
          <div className="input-group">
            <div className="groupInput">
              <label>موقف التنفيذ</label>
              <input
                type="text"
                value={
                  data.situation === "finish"
                    ? "تم التنفيذ"
                    : data.situation === "pending"
                    ? "جاري"
                    : "لم ينتهي"
                }
                readOnly
              />
            </div>
            <div className="groupInput">
              <label>وصف المشروع</label>
              <input type="text" value={data.workDescription} readOnly />
            </div>

            <div className="groupInput">
              <label> رقم المحطه</label>
              <input type="text" value={data.stationNumber} readOnly />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ShowDataPrivateProject = (data) => {
    return (
      <div>
        <div className="input-group">
          <div className="groupInput">
            <label>الفرع</label>
            <input type="text" value={data.branchName} readOnly />
          </div>
          <div className="groupInput">
            <label>المستشار</label>
            <input type="text" value={data.consultant} readOnly />
          </div>
        </div>
        <div className="input-group">
          <div className="groupInput">
            <label>قيمه المشروع</label>
            <input type="text" value={data.projectValue} readOnly />
          </div>
          <div className="groupInput">
            <label>مكان المشروع</label>
            <input type="text" value={data.projectPlace} readOnly />
          </div>
          <div className="groupInput">
            <label>اسم المشروع</label>
            <input type="text" value={data.projectName} readOnly />
          </div>
        </div>

        <div className="input-group">
          <div className="groupInput">
            <label>الحي</label>
            <input type="text" value={data.district} readOnly />
          </div>
          <div className="groupInput">
            <label>المحطه</label>
            <input type="text" value={data.stationNumber} readOnly />
          </div>
        </div>
        <div className="input-group">
          <div className="groupInput">
            <label>العميل</label>
            <input type="text" value={data.customer} readOnly />
          </div>
          <div className="groupInput">
            <label>مده التنفيذ</label>
            <input type="text" value={data.durationOfImplementation} readOnly />
          </div>
        </div>
      </div>
    );
  };
  const ShowDataProjects = (data) => {
    return (
      <div className="w-full">
        <div>
          <div className="input-group">
            <div className="groupInput">
              <label>الفرع</label>
              <input type="text" value={data.branchName} readOnly />
            </div>
            <div className="groupInput">
              <label>المستشار</label>
              <input type="text" value={data.consultant} readOnly />
            </div>

            <div className="groupInput">
              <label>المقاول</label>
              <input type="text" value={data.contractor} readOnly />
            </div>
          </div>
          <div className="input-group">
            <div className="groupInput">
              <label>الحي</label>
              <input type="text" value={data.district} readOnly />
            </div>
            <div className="groupInput">
              <label>مده التنفيذ</label>
              <input
                type="text"
                value={data.durationOfImplementation}
                readOnly
              />
            </div>

            <div className="groupInput">
              <label>المكتب</label>
              <input type="text" value={officeNameMap[data.office]} readOnly />
            </div>
          </div>
          <div className="input-group">
            <div className="groupInput">
              <label>القيمه الفعليه</label>
              <input type="text" value={data.actualValue} readOnly />
            </div>
            <div className="groupInput">
              <label>القيمه المقدره</label>
              <input type="text" value={data.estimatedValue} readOnly />
            </div>

            <div className="groupInput">
              <label>رقم المستخلص</label>
              <input type="text" value={data.extractNumber} readOnly />
            </div>
          </div>
          <div className="input-group">
            <div className="groupInput">
              <label>موقف التنفيذ</label>
              <input
                type="text"
                value={
                  data.situation === "finish"
                    ? "تم التنفيذ"
                    : data.situation === "pending"
                    ? "جاري"
                    : "لم ينتهي"
                }
                readOnly
              />
            </div>
            <div className="groupInput">
              <label>وصف المشروع</label>
              <input type="text" value={data.workDescription} readOnly />
            </div>

            <div className="groupInput">
              <label> رقم المحطه</label>
              <input type="text" value={data.stationNumber} readOnly />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="form-container" id="form-container" dir="rtl">
      <div className="FormData">
        <div className="container">
          <h3>بيانات الطلب</h3>
          <p>Quote is generated upon loading the form</p>
          {ApiData.type === "المشاريع الخاصة"
            ? ShowDataPrivateProject(ApiData)
            : ApiData.type.trim() === "الصيانة"
            ? ShowDataMaintains(ApiData)
            : ApiData.type === "العمليات"
            ? ShowDataOperations(ApiData)
            : ShowDataProjects(ApiData)}

          <div className="upload-section">
            <h4>النماذج</h4>
            {ApiData.modelPhotos && ApiData.modelPhotos.length > 0 ? (
              <div className="file-list">
                {ApiData.modelPhotos.map((photo, index) => {
                  const fileUrl = `https://www.rasmconsult.com${photo.url}`;
                  const isPdf = fileUrl.endsWith(".pdf");
                  return isPdf ? (
                    <div key={index} className="file-item">
                      <a
                        href={fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "block", margin: "10px 0" }}
                      >
                        اضغط لرؤيه الملف
                      </a>
                    </div>
                  ) : (
                    <div key={index} className="file-item">
                      <a
                        href={fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={fileUrl}
                          alt={`Model Photo ${index + 1}`}
                          style={{
                            width: "100px",
                            height: "100px",
                            margin: "10px 0",
                          }}
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p>لا توجد نماذج.</p>
            )}
          </div>

          {/* Site Photos Section */}
          <div className="upload-section">
            <h4>صور الموقع</h4>
            {ApiData.sitePhotos && ApiData.sitePhotos.length > 0 ? (
              <div className="file-list">
                {ApiData.sitePhotos.map((photo, index) => {
                  const fileUrl = `https://www.rasmconsult.com${photo.url}`;
                  const isPdf = fileUrl.endsWith(".pdf");
                  return isPdf ? (
                    <div key={index} className="file-item">
                      <a
                        href={fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "block", margin: "10px 0" }}
                      >
                        اضغط لرؤيه الملف
                      </a>
                    </div>
                  ) : (
                    <div key={index} className="file-item">
                      <a
                        href={fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={fileUrl}
                          alt={`Site Photo ${index + 1}`}
                          style={{
                            width: "100px",
                            height: "100px",
                            margin: "10px 0",
                          }}
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p>لا توجد صور للموقع.</p>
            )}
          </div>

          {/* Safety Waste Photos Section */}
          <div className="upload-section">
            <h4>مخالفات السلامة</h4>
            {ApiData.safetyWastePhotos &&
            ApiData.safetyWastePhotos.length > 0 ? (
              <div className="file-list">
                {ApiData.safetyWastePhotos.map((photo, index) => {
                  const fileUrl = `https://www.rasmconsult.com${photo.url}`;
                  const isPdf = fileUrl.endsWith(".pdf");
                  return isPdf ? (
                    <div key={index} className="file-item">
                      <Link
                        to={fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "block", margin: "10px 0" }}
                      >
                        اضغط لرؤيه الملف
                      </Link>
                    </div>
                  ) : (
                    <div key={index} className="file-item">
                      <a
                        href={fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={fileUrl}
                          alt={`Safety Photo ${index + 1}`}
                          style={{
                            width: "100px",
                            height: "100px",
                            margin: "10px 0",
                          }}
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p>لا توجد مخالفات سلامة.</p>
            )}
          </div>

          <div className="input-group">
            <textarea value={note} readOnly />
          </div>
          <div className="button-group">
            <button
              type="button"
              className="export-button"
              onClick={handlePrint}
              style={{ background: "rgba(76, 175, 79, 1)", color: "white" }}
            >
              طباعة
            </button>
            <button
              type="button"
              className="export-button"
              onClick={handleExportExcel}
              style={{ background: "rgba(76, 175, 79, 1)", color: "white" }}
            >
              تصدير إلى إكسل
            </button>
            <button
              type="button"
              className="export-button"
              onClick={() => setShowModal(true)}
              style={{ background: "rgba(244, 67, 54, 1)", color: "white" }}
            >
              حذف الطلب
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modal-open">
          <div className="modal-content">
            <h2>تأكيد الحذف</h2>
            <p>هل أنت متأكد أنك تريد حذف هذا الطلب؟</p>
            <button className="close-modal-button" onClick={handleDelete}>
              نعم
            </button>
            <button
              className="close-modal-button"
              onClick={() => setShowModal(false)}
            >
              لا
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;
