import React from 'react';
import img from "../../Image/image 2.png"
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
    return (
        <header className="header" dir='rtl'>
            <div className="header-image">
                <Link to="/home-page"><img src={img} alt="Logo" /></Link>
            </div>
        </header>
    );
};

export default Header;
