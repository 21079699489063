import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import Orders from "./Order";

function Order() {
    return (
        <div className="apDiv order">
            <Sidebar />
            <div className="body_container" dir="rtl">
                <div className="Header">
                    <Header />
                </div>
                <Orders/> 
            </div>
        </div>
    );
}

export default Order;
