import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import SearchRequests from "./SearchRequests";

function SearchRequest() {
    return (
        <div className="apDiv SearchRequests">
            <Sidebar />
            <div className="body_container" dir="rtl">
                <div className="Header">
                    <Header />
                </div>
                <SearchRequests/> 
            </div>
        </div>
    );
}

export default SearchRequest;
