import React from 'react';
import './Card.css';
import img1 from "../../../Image/Group (3).png"
import img2 from "../../../Image/Frame (4).png"
import img3 from "../../../Image/Frame (5).png"

const Cards = ({ordersCountInRiyadhAndHail , statisticsOrders}) => {

    const ordersCount = ordersCountInRiyadhAndHail ? ordersCountInRiyadhAndHail : "";
    const Statistics = statisticsOrders ? statisticsOrders : "";
    
    const cardsData = [
        { id: 1, img: img1, number: ordersCount.riyadhEngineersCount, title: 'مهندسين فرع الرياض' },
        { id: 2, img: img1, number: ordersCount.hailEngineersCount, title: 'مهندسين فرع حائل ' },
        { id: 3, img: img2, number: Statistics.completedOrdersCount, title: 'الطلبات المكتملة' },
        { id: 4, img: img3, number:Statistics.nonCompletedOrdersCount , title: 'الطلبات المؤرشفة' },
    ];

    return (
        <>
            <div className="cards" id="BarChart-Cards">
                <div className='cards-container'>
                    {cardsData.map((card) => (
                        <div
                            key={card.id}
                            className="card"
                        >
                            <img src={card.img} alt='' />
                            <div>
                                <div className="card-number">{card.number}</div>
                                <div className="card-title">{card.title}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </>
    );
};

export default Cards;
