import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import Accounts from "./Accounts"
import "./Accounts.css";

function Account() {
    return (
        <div className="apDiv Accounts">
            <Sidebar />
            <div className="body_container" dir="rtl">
                <div className="Header">
                    <Header />
                </div>
                <Accounts/>
            </div>
        </div>
    );
}

export default Account;
