import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import Engineers from "./Engineers"

function Engineer() {
    return (
        <div className="apDiv Engineer">
            <Sidebar />
            <div className="body_container" dir="rtl">
                <div className="Header">
                    <Header />
                </div>
                <Engineers/>
            </div>
        </div>
    );
}

export default Engineer;
